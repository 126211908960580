<template>
  <div>
    <el-form
      label-position="top"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      @submit.native.prevent="doSubmit"
      class="form"
      ref="form"
      v-if="model"
    >
      <el-form-item
        :label="fields.austrittsdatum.label"
        :prop="fields.austrittsdatum.name"
        :required="fields.austrittsdatum.required"
      >
        <el-date-picker
          :format="elementUiDateFormat"
          placeholder
          v-model="model[fields.austrittsdatum.name]"
        ></el-date-picker>
      </el-form-item>

      <el-form-item
        :label="fields.abmeldegrund.label"
        :prop="fields.abmeldegrund.name"
        :required="fields.abmeldegrund.required"
      >
        <app-abmeldegrund-autocomplete-input
          :fetchFn="fields.abmeldegrund.fetchFn"
          :mapperFn="fields.abmeldegrund.mapperFn"
          :showCreate="false"
          v-model="model[fields.abmeldegrund.name]"
        ></app-abmeldegrund-autocomplete-input>
      </el-form-item>

      <el-form-item>
        <div class="form-buttons">
          <el-button
            :disabled="saveLoading"
            @click="doSubmit"
            icon="el-icon-fa-floppy-o"
            type="primary"
          >
            <app-i18n code="common.save"></app-i18n>
          </el-button>

          <el-button
            :disabled="saveLoading"
            @click="doCancel"
            icon="el-icon-fa-close"
          >
            <app-i18n code="common.cancel"></app-i18n>
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AbmeldungModel } from '@/modules/abmeldung/abmeldung-model';
import { FormSchema } from '@/shared/form/form-schema';

const { fields } = AbmeldungModel;
const formSchema = new FormSchema([fields.austrittsdatum, fields.abmeldegrund]);

export default {
  name: 'app-abmeldung-form',

  props: ['saveLoading', 'modal'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      elementUiDateFormat: 'layout/elementUiDateFormat',
    }),

    fields() {
      return fields;
    },
  },

  methods: {
    doCancel() {
      this.$emit('cancel');
    },
    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const values = formSchema.cast(this.model);

      return this.$emit('submit', {
        values,
      });

      /*  const austrittsdatum = moment(this.model.austrittsdatum)
        .utcOffset(0, true)
        .format('YYYY-MM-DD');
      return this.$emit('submit', { austrittsdatum }); */
    },
  },
};
</script>

<style></style>
