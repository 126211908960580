<template>
  <div>
    <i class="openDrawerButton el-icon-arrow-left" @click="drawer = true"></i>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/mitarbeiter' }">
        <app-i18n code="entities.mitarbeiter.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <strong>{{ mitarbeiterFullName }}</strong>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="app-content-page" v-loading="showSpinner">
      <el-row :gutter="10">
        <el-col :span="22">
          <app-mitarbeiter-form
            :isEditing="isEditing"
            :record="record"
            :saveLoading="showSpinner"
            :isInactive="isInactive"
            :isFormDisabled="isFormDisabled"
            @cancel="doCancel"
            @submit="doSubmit"
            v-if="!findLoading"
            ref="form"
          />
        </el-col>
        <el-col :span="2" :offset="0">
          <div class="actions box-card">
            <el-card shadow="never">
              <div slot="header">
                <span>Aktionen</span>
              </div>
              <div class="div-btn" v-if="isVertragButtonShown">
                <el-tooltip
                  :content="newVertragButtonTooltip"
                  :disabled="!newVertragButtonTooltip"
                >
                  <span>
                    <el-button
                      type="text"
                      @click="doShowVertragDialog()"
                      :disabled="isNewVertragButtonDisabled"
                    >
                      Neuer Vertrag
                    </el-button>
                  </span>
                </el-tooltip>
              </div>
              <div class="div-btn" v-if="isVertragsergaenzungButtonShown">
                <el-button
                  type="text"
                  @click="doShowVertragsergaenzungDialog()"
                >
                  Vertragsergänzung
                </el-button>
              </div>
              <div class="div-btn" v-if="isVertragsaenderungButtonShown">
                <el-button type="text" @click="doShowVertragsaenderungDialog()">
                  Vertragsänderung
                </el-button>
              </div>
              <div class="div-btn" v-if="isBestaetigungButtonShown">
                <el-button
                  type="text"
                  @click="dialogBestaetigungVisible = true"
                >
                  Bestätigung
                </el-button>
              </div>
              <div class="div-btn">
                <el-button type="text" @click="dialogCalendarVisible = true">
                  Erinnerung
                </el-button>
              </div>
              <div class="div-btn" v-if="isBeendigungButtonShown">
                <el-button type="text" @click="doShowBeendigungDialog()">
                  Kündigung
                </el-button>
              </div>
              <div class="div-btn" v-if="isAbmeldenButtonShown">
                <el-button type="text" @click="dialogAbmeldungVisible = true">
                  Abmelden
                </el-button>
              </div>
              <div class="div-btn" v-if="isNewActivationButtonShown">
                <el-button type="text" @click="startActivation">
                  Neu Aktiv
                </el-button>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
    <div>
      <portal to="modal">
        <app-bestaetigung-portal
          v-if="dialogBestaetigungVisible"
          :id="id"
          :visible="dialogBestaetigungVisible"
          :fullName="fullNameForDocument"
          @close="dialogBestaetigungVisible = false"
          @success="dialogBestaetigungVisible = false"
        ></app-bestaetigung-portal>
        <app-abmeldung-form-modal
          v-if="dialogAbmeldungVisible"
          :visible="dialogAbmeldungVisible"
          @close="dialogAbmeldungVisible = false"
          @doAbmelden="doAbmelden"
        ></app-abmeldung-form-modal>
        <app-calendar-form-modal
          :mitarbeiterId="id"
          :visible="dialogCalendarVisible"
          @close="dialogCalendarVisible = false"
          @success="dialogCalendarVisible = false"
          v-if="dialogCalendarVisible"
        ></app-calendar-form-modal>
      </portal>
    </div>
    <div style="position: relative; with: 100%">
      <el-drawer
        title="Notizen"
        :visible.sync="drawer"
        :with-header="true"
        style="overflow: auto"
        v-if="id"
      >
        <app-mitarbeiter-notiz
          :records="notiz"
          @doCreateNotiz="createNotiz"
          :mitarbeiter="id"
        ></app-mitarbeiter-notiz>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import MitarbeiterForm from '@/modules/mitarbeiter/components/mitarbeiter-form';
import MitarbeiterNotiz from '@/modules/mitarbeiter/components/mitarbeiter-notiz.vue';
import BestaetigungPortal from '@/modules/bestaetigung/components/bestaetigung-portal.vue';
import CalendarFormModal from '@/modules/calendar/components/calendar-item-form-modal.vue';
import AbmeldungModal from '@/modules/abmeldung/components/abmeldung-form-modal.vue';
import { MitarbeiterPermissions } from '@/modules/mitarbeiter/mitarbeiter-permissions';
import { routerAsync } from '@/app-module';
import { FileUploader } from '@/shared/file-upload/file-uploader';
import { i18n } from '@/i18n';
export default {
  name: 'app-mitarbeiter-form-page',
  props: ['id'],
  components: {
    [MitarbeiterForm.name]: MitarbeiterForm,
    [MitarbeiterNotiz.name]: MitarbeiterNotiz,
    [BestaetigungPortal.name]: BestaetigungPortal,
    [AbmeldungModal.name]: AbmeldungModal,
    [CalendarFormModal.name]: CalendarFormModal,
  },
  data() {
    return {
      isSaving: false,
      drawer: false,
      dialogBestaetigungVisible: false,
      dialogAbmeldungVisible: false,
      dialogCalendarVisible: false,
    };
  },
  async created() {
    if (this.isEditing) {
      await this.doFind(this.id);
      this.doGetAnstellungHistory();
      this.doFetchNotiz(this.id);
      this.doSetFilterAndFetchVertrag();
      this.doSetFilterAndFetchVertragsergaenzung();
      this.doSetFilterAndFetchVertragsaenderung();
      this.doSetFilterAndFetchBeendigung();
      this.$refs;
    } else {
      await this.doNew();
      await this.doResetVertrag();
      await this.doResetBeendigung();
      await this.doResetAnstellungHistory();
    }
  },
  mounted() {
    this.$store.dispatch(
      'mitarbeiter/form/doSetEditInactivePermission',
      this.hasPermissionToEditInactive,
    );
  },
  computed: {
    ...mapGetters({
      record: 'mitarbeiter/form/record',
      findLoading: 'mitarbeiter/form/findLoading',
      saveLoading: 'mitarbeiter/form/saveLoading',
      saveLoadingBeendigung: 'beendigung/saveLoading',
      saveLoadingKarenz: 'karenz/form/saveLoading',
      currentUser: 'auth/currentUser',
      showSpinner: 'mitarbeiter/form/showSpinner',
      filter: 'mitarbeiter/list/filter',
      pendingFiles: 'mitarbeiter/form/pendingFiles',
      notiz: 'mitarbeiter/form/notiz',
      isActive: 'mitarbeiter/form/isActive',
      isInactive: 'mitarbeiter/form/isInactive',
      isInPlan: 'mitarbeiter/form/isInPlan',
      eintrittsdatum: 'mitarbeiter/form/eintrittsdatum',
      reActivationStarted: 'mitarbeiter/form/reActivationStarted',
      isFormDisabled: 'mitarbeiter/form/isFormDisabled',
      hasPermissionToCreateBeendigung: 'beendigung/hasPermissionToCreate',
      hasPermissionToCreateVertrag: 'vertrag/hasPermissionToCreate',
      hasPermissionToCreateBestaetigung: 'bestaetigung/hasPermissionToCreate',
    }),
    isEditing() {
      return !!this.id || this.reActivationStarted;
    },
    mitarbeiterFullName() {
      return this.record ? this.record.vollname : '';
    },
    hasPermissionToSendPayrollRegistration() {
      return new MitarbeiterPermissions(this.currentUser).edit;
    },
    hasPermissionToAbmelden() {
      return new MitarbeiterPermissions(this.currentUser).abmelden;
    },
    hasPermissionToSetBackToActive() {
      return new MitarbeiterPermissions(this.currentUser).setBackToActive;
    },
    hasPermissionToEditInactive() {
      return new MitarbeiterPermissions(this.currentUser)
        .editInactiveMitarbeiter;
    },
    isVertragButtonShown() {
      return this.isEditing && this.hasPermissionToCreateVertrag;
    },
    isVertragsergaenzungButtonShown() {
      return this.isEditing && this.hasPermissionToCreateVertrag;
    },
    isVertragsaenderungButtonShown() {
      return this.isEditing && this.hasPermissionToCreateVertrag;
    },
    isBestaetigungButtonShown() {
      return (
        this.isEditing &&
        this.hasPermissionToCreateBestaetigung &&
        this.isActive
      );
    },
    isBeendigungButtonShown() {
      return this.isEditing && this.hasPermissionToAbmelden;
    },
    isAbmeldenButtonShown() {
      return this.isEditing && this.hasPermissionToAbmelden;
    },
    isNewActivationButtonShown() {
      return (
        this.isEditing && this.hasPermissionToCreateVertrag && this.isInactive
      );
    },
    isNewVertragButtonDisabled() {
      return !(
        this.isEditing &&
        this.hasPermissionToCreateVertrag &&
        (this.isInPlan || this.isActive)
      );
    },
    newVertragButtonTooltip() {
      return !this.isInPlan || !this.isActive
        ? 'Bitte speichern Sie die aktuellen Änderungen, um einen Vertrag zu erstellen.'
        : null;
    },

    filter() {
      return {
        filter: {
          id: this.id,
        },
      };
    },
    fullNameForDocument() {
      return this.isEditing && this.record
        ? `${this.record.nachname}_${this.record.vorname}`
        : '';
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (
      this.isSaving ||
      this.saveLoadingBeendigung ||
      this.saveLoadingKarenz ||
      to.name.includes('error')
    ) {
      next();
      return;
    }
    try {
      await this.$confirm(
        i18n('common.discardFormConfirm'),
        i18n('common.confirm'),
        {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
          closeOnClickModal: false,
          closeOnPressEscape: false,
        },
      );
      let response;
      if (this.pendingFiles.length > 0) {
        response = await FileUploader.removePendingFiles(this.pendingFiles);
        if (response === 200) next();
      }
      next();
    } catch (error) {
      next(false);
    }
  },
  methods: {
    ...mapActions({
      doFind: 'mitarbeiter/form/doFind',
      doNew: 'mitarbeiter/form/doNew',
      doUpdate: 'mitarbeiter/form/doUpdate',
      doCreate: 'mitarbeiter/form/doCreate',
      doResetMitarbeiterStatus: 'mitarbeiter/form/doResetMitarbeiterStatus',
      doAbmeldenStore: 'mitarbeiter/form/doAbmelden',
      doSetKarenzvertretung: 'mitarbeiter/form/doSetKarenzvertretung',
      doResetVertrag: 'vertrag/doReset',
      doResetBeendigung: 'beendigung/doReset',
      doFetchHistory: 'mitarbeiter/history/doFetch',
      doResetHistory: 'mitarbeiter/history/doResetStates',
      doFetchNotiz: 'mitarbeiter/form/doFetchNotiz',
      doCreateNotiz: 'mitarbeiter/form/doCreateNotiz',
      doFetchVertrag: 'vertrag/doFetch',
      doFetchVertragsergaenzung: 'vertragsergaenzung/doFetch',
      doFetchVertragsaenderung: 'vertragsaenderung/doFetch',
      doFetchBeendigung: 'beendigung/doFetch',
    }),
    doCancel() {
      routerAsync().push('/mitarbeiter');
    },
    async doSubmit(payload) {
      this.isSaving = true;
      if (this.isEditing) {
        if (this.reActivationStarted) {
          payload.reActivationStarted = true;
        }
        await this.doUpdate(payload);
      } else {
        await this.doCreate(payload.values);
      }
      this.isSaving = false;
    },
    async doAbmelden(payload) {
      this.isSaving = true;
      await this.doAbmeldenStore({ id: this.id, values: payload });
      this.dialogAbmeldungVisible = false;
      this.isSaving = false;
    },

    startActivation() {
      this.doResetMitarbeiterStatus();
    },

    async doGetAnstellungHistory() {
      return this.doFetchHistory(this.filter);
    },
    async doResetAnstellungHistory() {
      return this.doResetHistory();
    },
    async doSetFilterAndFetchVertrag() {
      return this.doFetchVertrag(this.filter);
    },
    async doSetFilterAndFetchVertragsergaenzung() {
      return this.doFetchVertragsergaenzung(this.filter);
    },
    async doSetFilterAndFetchVertragsaenderung() {
      return this.doFetchVertragsaenderung(this.filter);
    },
    async doSetFilterAndFetchBeendigung() {
      return this.doFetchBeendigung(this.filter);
    },
    async createNotiz(payload) {
      try {
        this.doCreateNotiz(payload);
      } catch (error) {
        return;
      }
    },
    doShowVertragsergaenzungDialog() {
      this.$store.dispatch('vertragsergaenzung/doShowFormDialog');
    },
    doShowVertragsaenderungDialog() {
      this.$store.dispatch('vertragsaenderung/doShowFormDialog');
    },
    doShowVertragDialog() {
      this.$store.dispatch('vertrag/doShowFormDialog');
    },
    doShowBeendigungDialog() {
      this.$store.dispatch('beendigung/doShowFormDialog');
    },
    /* ---------------------------------------KARENZ------------------------------------- */

    async doSubmitKarenzvertretung(payload) {
      this.isSaving = true;
      await this.doSetKarenzvertretung(payload);
      this.isSaving = false;
    },
    /* ---------------------------------------KARENZ------------------------------------- */
  },
};
</script>
<style scoped>
.openDrawerButton {
  float: right;
}
</style>
