import { i18n } from '@/i18n';
import { GenericModel } from '@/shared/model/generic-model';
import DateField from '@/shared/fields/date-field';
import { AbmeldegrundField } from '@/modules/abmeldegrund/abmeldegrund-field';

function label(name) {
  return i18n(`entities.abmeldung.fields.${name}`);
}

const fields = {
  austrittsdatum: new DateField('austrittsdatum', label('austrittsdatum'), {
    required: true,
  }),
  abmeldegrund: AbmeldegrundField.relationToOne(
    'abmeldegrund',
    label('abmeldegrund'),
    {
      required: true,
    },
  ),
};

export class AbmeldungModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
